import axios from 'axios'

const RESOURCE_NAME = '/products'

export default {
  getCategoriesWithProducts () {
    return axios.get(`${RESOURCE_NAME}/categories`)
  },
  update (id, data) {
    return axios.post(`${RESOURCE_NAME}/${id}`, data)
  },
  create (data) {
    return axios.post(`${RESOURCE_NAME}/create`, data)
  }
}
