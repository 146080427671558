<template>
  <div>
    <div class="products-container">
        <h3 class="section-heading"> {{$t('headings.products')}} </h3>
        <div class="products-container-header">
            <div class="search-products">
                <div class="input-group">
                    <input type="text" id="search-products" placeholder="Cauta produse..." name=""  v-model="q">
                    <button type="button" class="general-icon-button">
                        <i class="fas fa-search"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="product-container" v-if="searchProducts.length <= 0">
            <div class="products-list" v-if="categoriesList && categoriesList.parent && categoriesList.parent.id">
                <b-row>
                    <b-col xl="3" lg="3" md="4" sm="6" class="mg-b">
                        <div class="item-box category-box" @click="setCategoryList(categoriesList.parentList, getParentListById(categories, categoriesList.parentList.id))">
                            <span>
                                <i class="fas fa-arrow-left"></i>
                            </span>
                        </div>
                    </b-col>
                    <b-col xl="7" lg="7" md="8" sm="6" class="mg-b">
                        <h3 class="category-title">
                            {{categoriesList.parent.name}}
                        </h3>
                    </b-col>
                </b-row>
            </div>
            <div class="products-list" v-if="categoriesList">
                <b-row>
                    <b-col xl="3" lg="3" md="4" sm="6" class="mg-b" v-for="(category, key) in categoriesList.subcategories"  :key="key">
                        <div class="item-box category-box" @click="setCategoryList(category, categoriesList)">
                            <span>
                                {{category.name}}
                            </span>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col xl="2" lg="2" md="3" sm="6" class="mg-b" v-for="(product, key) in categoriesList.products"  :key="key">
                        <div class="item-box product-box">
                            <button class="item-box-overlay" @click="addProduct(product)">
                                <i class="fas fa-plus"></i>
                            </button>
                            <span>
                                {{product.name}}
                            </span>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </div>
        <div class="product-container" v-if="searchProducts || searchProducts.length > 0">
            <div class="products-list">
                <b-row>
                    <b-col xl="2" lg="2" md="3" sm="6" class="mg-b" v-for="(product, key) in searchProducts"  :key="key">
                        <div class="item-box product-box">
                            <button class="item-box-overlay" @click="addProduct(product)">
                                <i class="fas fa-plus"></i>
                            </button>
                            <span>
                                {{product.name}}
                            </span>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
  </div>
</template>

<script>

import ProductService from '@/api-services/product.service.js'

export default {
  name: 'CategoriesProducts',
  props: {

  },
  data () {
    return {
      q: null,
      searchProducts: [],
      categories: null,
      categoriesList: null
    }
  },
  watch: {
    q: window._.debounce(async function (val) {
      this.searchProducts = this.searchProductsInCategs(this.q)
    }, 20)
  },
  methods: {
    async getCategoriesWithProducts () {
      await ProductService.getCategoriesWithProducts().then((response) => {
        this.categories = { 'subcategories': response.data.data }
        this.categoriesList = window._.clone(this.categories)
      })
    },
    setCategoryList (category, categoriesListParent = null) {
      this.categoriesList = window._.clone(category)
      this.categoriesList.parentList = window._.clone(categoriesListParent)
      this.categoriesList.parent = window._.clone(categoriesListParent ? category : null)
    },
    getParentListById (categories, categoryId) {
      for (let i in categories.subcategories) {
        if (parseInt(categories.subcategories[i].id) === parseInt(categoryId)) {
          return categories
        } else {
          let res = this.getParentListById(categories.subcategories[i], categoryId)
          if (res) return res
        }
      }
      return null
    },
    searchProductsInCategs (q) {
      let output = []
      let search = q
      search = search.replace(/\s+/g, ' ')
      let keywords = search.toLowerCase().split(' ')
      if (search !== '' && keywords && keywords.length > 0) {
        for (let i in this.categories.subcategories) {
          let products = this.search(keywords, this.categories.subcategories[i])
          output.push.apply(output, products)
        }
      }
      return output.sort((a, b) => (a.score > b.score) ? -1 : 1)
    },
    search (keywords, categ) {
      let products = []
      if (categ.products) {
        let score = 0
        products = categ.products.filter(function (data) {
          score = 0
          data.name_search = data.name ? data.name.toLowerCase() : null

          for (let i = 0; i < keywords.length; i++) {
            if (keywords[i] !== '' && data.name_search && data.name_search.includes(keywords[i])) {
              score += keywords.length - i
            }
          }

          if (score > 0) {
            data['score'] = score
            return data
          }
        })
      }
      for (let i in categ.subcategories) {
        let productsFromSubcateg = this.search(keywords, categ.subcategories[i])
        products.push.apply(products, productsFromSubcateg)
      }
      return products
    },
    addProduct (product) {
      let existProd = this.$parent.orderProducts.find(p => parseInt(p.id) === parseInt(product.id))
      if (existProd) {
        existProd.qty++
      } else {
        this.$parent.orderProducts.push({
          'id': product.id,
          'name': product.name,
          'price': product.price,
          'qty': 1,
          'mentions': ''
        })
      }
    }
  },
  async created () {
    await this.getCategoriesWithProducts()
  }
}
</script>
